import styled from "@emotion/styled";
import { css } from '@emotion/core'
import { red, gray } from "../../components/eletrons/Colors";

export const globalStyles = css`
  body {
    background: ${gray.toString()}
  }

  b {
    color: ${red.toString()}
  }
`
