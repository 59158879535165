import React, { PropsWithChildren } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Query } from "../../graphql-types"
import generateIconLibrary from "../../components/eletrons/generateIconLibrary"
import "./typography.css"
import "./layout.css"
import SEO from "../../components/atoms/SEO/SEO"
import { Global } from "@emotion/core"
import { globalStyles } from "./layout.styles"

generateIconLibrary()

interface LayoutInterface {}

const LayoutBase = ({ children }: PropsWithChildren<LayoutInterface>) => {
  const data: Query = useStaticQuery(graphql`
    query BaseQuery {
      site {
        siteMetadata {
          title
          keywords
        }
      }
      allSocialsJson {
        edges {
          node {
            icon
            id
            label
            url
          }
        }
      }
    }
  `)

  return (
    <>
      <Global styles={globalStyles} />
      <SEO
        meta={[{ name: "keywords", content: data.site.siteMetadata.keywords }]}
      />
      {children}
    </>
  )
}

export default LayoutBase
